import React, { useState, useEffect } from 'react'
//import convert from 'xml-js'
import './App.css';
// import convert from 'xml-js'
//import http from 'https'

function App() {
console.log("asdfsadf")
  const [onlinePerfomersArr, setOnlinePerformersArr] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)

  // camgirls.slimandbusty.com/:1 Access to fetch at 'https://chaturbate.com/feed/latest/?gender=f&wm=TtVUW' from origin 'https://camgirls.slimandbusty.com' has been blocked by CORS policy: No 'Access-Control-Allow-Origin' header is present on the requested resource. If an opaque response serves your needs, set the request's mode to 'no-cors' to fetch the resource with CORS disabled.
  //GET https://chaturbate.com/feed/latest/?gender=f&wm=TtVUW net::ERR_FAILED 200 (OK)
  let getCamgirls = function(){

    // get approved usernames
    // let url = 'https://s3.amazonaws.com/slimandbusty.com/js/chaturbateApproved.json?ut=' + (+new Date)
    // fetch(url)
    //   .then(async res => {
    //     let t = await res.text()
    //     let appUsersArr = JSON.parse(t);
        //console.log("appUsersArr",appUsersArr)

        //url = "https://chaturbate.com/feed/latest/?gender=f&wm=TtVUW"
        let url = 'https://s3.amazonaws.com/slimandbusty.com/json/chaturbateApprovedOnline.json?ut=' + (+new Date())
        fetch(url)
          .then(async res => {
            console.log('res.status',res.status);
            setOnlinePerformersArr(await res.json());
            setIsLoaded(true)

            // can't pass res.body to xml2json() because res.body is 'ReadableStream' and not a string, so...
            //let r = await res.text();
            //let r = await new Response(res.body).text();
            // chaturbateJson = convert.xml2json(r, {
            //   compact: true,
            //   spaces: 4,
            //   trim:true
            // });
            //let onlineUArr = []
            // chaturbateJson = JSON.parse(chaturbateJson)
            // for(let i in chaturbateJson['rss']['channel']['item']) {
            //   let obj = chaturbateJson['rss']['channel']['item'][i]
            //   onlineUArr.push(obj['cb:username']['_text'])
            // }
            // console.log("onlineUArr", onlineUArr)
            // let onlineAppUArr = []
            // for(let u of onlineUArr){
            //   for(let obj of appUsersArr) {
            //     if (u === obj['username']) {
            //       onlineAppUArr.push(obj)
            //     }
            //   }
            // }
            // console.log("onlineApprovedUsers #", onlineAppUArr.length)
            // console.log("onlineApprovedUsers", onlineAppUArr)
            // setOnlineUsernamesArr(onlineAppUArr)
          })
      // })
  }


  // cronjob writes usernames that are online and approved to chaturbateApprovedOnline.json. This
  // function reads that file
  // function getonlineUsernames() {
  //   // get approved online usernames
  //   let url = 'http://slimandbusty.com/js/chaturbateApprovedOnline.json'
  //   url = 'https://s3.amazonaws.com/slimandbusty.com/js/chaturbateApprovedOnline.json?ut=' + (+new Date)
  //   fetch(url)
  //     .then(res => res.json())
  //     .then((result) => {
  //       // console.log(result[0])
  //       console.log("got result")
  //         setOnlineUsernamesArr(result)
  //         setIsLoaded(true)
  //       }
  //     )
  // }

  useEffect(() => {
    if (!isLoaded) {
      //getonlineUsernames();
      //console.log("B", onlineUsernamesArr)
      getCamgirls();
    }
  }, [isLoaded]);


  if (!isLoaded) {
  //   getonlineUsernames();
  //   //getonlineCB();
  //   setIsLoaded(true)
  // } else if (!isLoaded) {
    return null;
  } else {
    console.log("C", onlinePerfomersArr)

    return (

      <div className="camgirlscont">
        <h4><a href="https://slimandbusty.com">SlimAndBusty</a> camgirls online!</h4>
        {onlinePerfomersArr.map((obj, i) => {
          return <div className="camgirlcont" key={obj['username']}
                      dangerouslySetInnerHTML={{ __html: "<a target='_blank' href='" + obj['link'] + "'><img src='" + obj['imgSrc'] + "' class='thumb'></a><br><a target='_blank' href='" + obj['link'] + "'>" + obj['username'] + "</a>" }}/>
        })}
        <div className="clearBoth"/>
        <h4><a href="https://slimandbusty.com">SlimAndBusty.com</a></h4>
      </div>
    );
  }

}

export default App;
